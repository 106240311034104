import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

const PageNavigatorTrans = (props) => {
  const { 
    link, 
    text, 

  }  = props;
  
  return (
    <div className="bg-transparent flex flex-wrap flex-row px-12 py-6 justify-center sm:justify-start w-full">
      <Link href={link} className={`h-10 w-72 text-lg text-start text-white`}>
        <FontAwesomeIcon
          icon={faArrowLeftLong}
          className={`w-8 mr-2 text-lg fa-solid h-5 text-white`}
        />
        {text}
      </Link>
    </div>
  )
};

export default PageNavigatorTrans;
