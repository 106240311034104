import React from "react";
import Layout from "../../components/layout/Layout";
import { ContentLic } from "../../components/ContentLic";
import { HeroPromoFeature } from "../../components/HeroPromoFeature";
import PageNavigator from "../../components/layout/PageNavigator";
import license from "../../data/corsi-patente-nautica/patente-nautica-12-miglia/license.json";
import contact from "../../data/corsi-patente-nautica/patente-nautica-12-miglia/patente-nautica-contact.json";

import { patente } from "../../data/corsi-patente-nautica/patente-nautica-12-miglia/patente";
import { patentePack } from "../../data/corsi-patente-nautica/patente-nautica-12-miglia/patente-pack";
import { FeaturePackage } from "../../components/FeaturePackage";
import HeroContact from "../../components/HeroContact";
import PageNavigatorWhite from "../../components/layout/PageNavigatorWhite";
import { HeroHighlight } from "../../components/HeroHighlight";
import { HeroPhoto } from "../../components/HeroPhoto";
import PageNavigatorTrans from "../../components/layout/PageNavigatorTrans";
import ContactSection from "../../components/home-page/ContactSection";
import NauticaFeatureSection from "../../components/patente-nautica/NauticaFeatureSection";
import Seo from "../../components/layout/SEO";
import BannerCompass from "../../components/BannerCompass";
import BannerCompassWhite from '../../components/BannerCompassWhite';

const PatenteNautica12Miglia = () => {
  return (
    <Layout>
      <Seo
        title={"Patente Nautica 12 Miglia | Autoscuola Faro a Pisa"}
        description={"Consegui la patente nautica entro 12 miglia per imbarcazioni e moto d'acqua. Pratica in mare + corsi in sede a Pisa."}
        keywords={[
          "corso patente nautica 12 miglia Pisa Toscana",
          "scuola patente nautica barca a vela",
          "patente nautica italiana Pisa"
        ]}
      />

      <HeroPhoto
        title={patente.title}
        newLine={patente.newLine}
        highlight={patente.highlight}
        par1={patente.par1}
        par2={patente.par2}
        internalLink={patente.internalLink}
      />
      <FeaturePackage id="pack" pack={patentePack} />

      <ContentLic license={license} />

      <NauticaFeatureSection />
      <BannerCompassWhite/>

      {/* <HeroContact title={contact.title} description={contact.description} description1={contact.description1} description2={contact.description2} /> */}
      <ContactSection />
    </Layout>
  );
};

export default PatenteNautica12Miglia;
