import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";
import PageNavigatorTrans from "./layout/PageNavigatorTrans";

export const HeroPhoto = (props) => {
  const { title, newLine, highlight, par1, par2, internalLink } = props;

  return (
    <div className="relative">
      <img
        src="/patente-nautica/overlook.jpg"
        className="absolute inset-0 object-none object-[20%] w-full h-full"
        alt="Patente nautica con Autoscuola Faro a Pisa"

      />
      <div className="relative bg-gray-800 bg-opacity-80">
        <div className="absolute inset-x-0 bottom-0">
          <svg
            viewBox="0 0 224 12"
            fill="currentColor"
            className="w-full -mb-1 text-white"
            preserveAspectRatio="none"
          >
            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
          </svg>
        </div>
        <PageNavigatorTrans
          link="/patenti-nautiche"
          text="Corsi patenti nautiche"
          bgColor="blue-accent"
          bgIntensity="400"
          textColor="gray"
          textIntensity="100"
        />
        <div className="relative w-full sm:mx-auto sm:text-center py-24">
          <h1 className="p-4 sm:p-0 mb-6 font-sans  text-4xl font-extrabold tracking-tight text-white sm:text-6xl leading-8">
            Patente nautica?
            <br className="hidden md:block" />
            Sì, con {" "}
            <span className="relative inline-block px-2  mt-4">
              <div className="absolute inset-0 transform -skew-x-12 bg-teal-accent-400" />
              <span className="relative text-teal-900">{"Autoscuola Faro"}</span>
            </span>
          </h1>
          <h2 className="p-4 sm:p-0 mt-6 mb-6 text-white font-roboto text-2xl font-semibold tracking-tight">
          Preparati a salpare grazie al nuovo corso per la patente nautica entro 12 miglia, a Pisa. <br />
           I docenti con esperienza pluriennale ti guideranno tra pratica in mare e teoria su carta. 
          </h2>
          <Link
            to={internalLink}
            title="Step"
            className="flex cursor-pointer items-center justify-center w-10 h-10 mx-auto text-white duration-300 transform border border-gray-400 rounded-full hover:text-teal-accent-400 hover:border-teal-accent-400 hover:shadow hover:scale-110"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="currentColor"
            >
              <path d="M10.293,3.293,6,7.586,1.707,3.293A1,1,0,0,0,.293,4.707l5,5a1,1,0,0,0,1.414,0l5-5a1,1,0,1,0-1.414-1.414Z" />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};
