import React, { useState, useRef } from "react";
import { Link } from "gatsby";
import emailjs from "@emailjs/browser";

import "../css/clip.css";
import { Modal } from "./Modal";
import { ErrorModal } from "./ErrorModal";

export const HeroContact = (props) => {
  const { id, title, description, description1, description2 } = props;

  const [openModal, setOpenModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // usare template id per la patente nautica
    emailjs
      .sendForm(
        "service_uuz9e3b", // service ID from EmailJS
        "template_vbita4h", //template ID from EmailJS
        form.current,
        "_uQ01AzQbXOfXxhng" // public key ID from EmailJS
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          setOpenModal(true);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          setOpenErrorModal(true);
        }
      );
  };

  return (
    <div className="relative">
      <Modal open={openModal} closeModal={() => setOpenModal(false)} />
      <ErrorModal
        open={openErrorModal}
        closeModal={() => setOpenModal(false)}
      />

      <img
        src="/patente-nautica/ormeggio.jpg"
        className="absolute inset-0 object-cover w-full h-full"
        alt=""
      />
      <div className="relative bg-opacity-80 bg-gray-900">
        <svg
          className="absolute inset-x-0 bottom-0 text-white"
          viewBox="0 0 1160 163"
        >
          <path
            fill="currentColor"
            d="M -165 47 Q -40 166 461 80 Q 583 60 621 110 C 698 220 888 -121 1024 60 Q 1132 206 1329 15 V 378 H -164 Z"
          />
        </svg>
        <div className="relative px-4 py-16 mx-auto overflow-hidden sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="flex flex-col items-center justify-between xl:flex-row">
            <div className="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12">
              <h2 className="font-roboto font-semibold tracking-tighter max-w-lg mb-6 font-sans sm:text-6xl text-4xl text-teal-accent-400 sm:leading-none">
                {title}
              </h2>
              <p className="max-w-xl mb-4 text-lg text-gray-200 md:text-xl">
                {description}
              </p>
              <p className="max-w-xl mb-4 text-lg text-gray-200 md:text-xl">
                {description1}
              </p>
              <p className="max-w-xl mb-4 text-lg text-gray-200 md:text-xl">
                {description2}
              </p>
            </div>
            <div className="w-full max-w-xl xl:px-8 xl:w-5/12">
              <div className="bg-white rounded shadow-2xl p-7 sm:p-10">
                <h3 className="mb-4 text-2xl font-roboto font-bold tracking-tighter sm:text-center sm:mb-6 sm:text-4xl">
                  Scrivici per saperne di più
                </h3>
                <form ref={form} onSubmit={sendEmail}>
                  <div className="mb-1 sm:mb-2">
                    <label
                      htmlFor="firstName"
                      className="inline-block mb-1 text-lg font-semibold font-roboto"
                    >
                      Nome
                    </label>
                    <input
                      placeholder="Mario"
                      required
                      type="text"
                      className="text-xl flex-grow w-full h-14 px-4 mb-2 transition duration-500 bg-gray-200  focus:bg-gray-300 text-gray-900 border-gray-300 rounded shadow-sm appearance-none border-4 focus:border-blue-accent-400 focus:outline-none focus:shadow-outline"
                      id="firstName"
                      name="first_name"
                    />
                  </div>
                  <div className="mb-1 sm:mb-2">
                    <label
                      htmlFor="lastName"
                      className="inline-block mb-1 text-lg font-semibold font-roboto"
                    >
                      Cognome
                    </label>
                    <input
                      placeholder="Rossi"
                      required
                      type="text"
                      className="text-xl flex-grow w-full h-14 px-4 mb-2 transition duration-500 bg-gray-200  focus:bg-gray-300 text-gray-900 border-gray-300 rounded shadow-sm appearance-none border-4 focus:border-blue-accent-400 focus:outline-none focus:shadow-outline"
                      id="lastName"
                      name="last_name"
                    />
                  </div>
                  <div className="mb-1 sm:mb-2">
                    <label
                      htmlFor="email"
                      className="inline-block mb-1 text-lg font-semibold font-roboto"
                    >
                      E-mail
                    </label>
                    <input
                      placeholder="john.doe@example.org"
                      required
                      type="text"
                      className="text-xl flex-grow w-full h-14 px-4 mb-2 transition duration-500 bg-gray-200  focus:bg-gray-300 text-gray-900 border-gray-300 rounded shadow-sm appearance-none border-4 focus:border-blue-accent-400 focus:outline-none focus:shadow-outline"
                      id="email"
                      name="user_mail"
                    />
                  </div>
                  <div className="mt-4 mb-2 sm:mb-4">
                    <button
                      type="submit"
                      className="text-xl inline-flex items-center justify-center w-full h-12 px-6 font-bold font-roboto tracking-tight text-white transition duration-200 rounded shadow-md bg-blue-accent-400 hover:bg-blue-accent-700 focus:shadow-outline focus:outline-none"
                    >
                      Iscriviti
                    </button>
                  </div>
                  <p className="text-xs text-gray-600 sm:text-sm">
                    Rispettiamo la tua privacy. Disicriviti quando vuoi.
                  </p>
                  <Link href="/privacy-policy">
                    <p className="text-xs text-gray-600 sm:text-sm">
                      Leggi i nostri termini di privacy policy
                    </p>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroContact;
