import { faBolt, faCar, faPen } from "@fortawesome/free-solid-svg-icons";

export const patente = {
  brand: "Patente Nautica 2.0",
  title: "Patente nautica? ",
  newLine: "Sì, con ",
  highlight: "Autoscuola Faro!",
  par1: "Preparati a salpare grazie al nuovo corso per la patente nautica entro 12 miglia, a Pisa. I docenti con esperienza pluriennale ti guideranno tra pratica in mare e teoria su carta.",
  par2: "",
  internalLink: "#pack",
};
