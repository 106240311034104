import React from "react";

export const ContentLic = ({ id, license, vehicles }) => {
  return (
    <section id={id} className="bg-white">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="lg:w-full mx-auto">
          <h1 className="sm:text-start text-center text-blue-accent-700 sm:text-6xl text-4xl font-sans font-extrabold tracking-tight">
            {license.title}
          </h1>
          <div className="flex lg:flex-row flex-col md:flex-col mt-4 sm:mt-10">
            <div className="w-full md:w-full lg:w-1/2 sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left">
              <div className="sm:mb-4 mb-4 px-8 py-2">
                <h2 className="text-center text-blue-accent-700 text-2xl font-roboto font-bold tracking-tight ">
                  {license.vehicleDescription}
                </h2>
                <p className="text-center font-roboto font-bold tracking-tight text-gray-900">
                  {license.transmission}
                </p>
              </div>
              {license.vehicles.map((v) => (
                <div key={v.id} className="sm:w-full text-center sm:py-8 py-8">
                  {/* Image */}
                  <div
                    className="lg:w-full md:w-3/4 w-full
                    h-56 
                    rounded-lg inline-flex items-center justify-center bg-gray-200 text-gray-400"
                  >
                    <img
                      alt={v.vehicleImg}
                      className="rounded-lg w-full h-full block inset-0"
                      src={v.vehicleImg}
                    />
                    {/* <StaticImage
                      alt={v.vehicleImg}
                      className="rounded-full w-full h-full block inset-0"
                      src="../images/boat.jpg"
                    /> */}
                  </div>
                  {/* Description */}
                  <div className="flex flex-col items-center text-center justify-center">
                    <div
                      className={`w-12 h-1 bg-gray-900 rounded mt-2 mb-4`}
                    ></div>
                    <h2 className="title-font font-roboto font-bold tracking-tight text-gray-900 text-lg px-16 py-2">
                      {v.text}
                    </h2>
                  </div>
                </div>
              ))}
            </div>
            <div className="order-first w-full md:w-full lg:w-1/2 sm:pr-8 sm:py-2 lg:border-r border-gray-400  md:border-t-0 sm:border-t-0 mt-4 pt-4 sm:mt-0 text-left sm:text-left">
              <h2 className="text-2xl title-font mb-4 mt-4 font-roboto font-bold tracking-tight text-blue-accent-700">
                {license.ageLabel}
              </h2>
              {license.minAge.map((el) => (
                <p
                  id={el.id}
                  className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900"
                >
                  {el.text}
                </p>
              ))}
              <h2 className="text-2xl title-font mb-4 font-roboto font-bold tracking-tight text-blue-accent-700">
                {license.licenseFor}
              </h2>

              <ul className="list-disc sm:ml-8 mt-4 ml-4">
                {license.vehicleTypes.map((el) => (
                  <li className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">
                    {el.text}
                    <ol id={el.id} className="list-disc sm:ml-8">
                      {el.characteristics.map((char) => (
                        <li id={char.id}>{char.text}</li>
                      ))}
                    </ol>
                  </li>
                ))}
              </ul>

              {/* Document section */}
              <h2 className="text-2xl title-font mt-16 font-roboto font-bold tracking-tight text-blue-accent-700">
                {license.documentsLabel}
              </h2>
              <ul className="list-disc sm:ml-8 mt-4 ml-4">
                {license.docs.map((el) => (
                  <li
                    id={el.id}
                    className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900"
                  >
                    {el.text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
