import { faBolt, faSailboat, faBook, faCompassDrafting, faCodeCommit } from '@fortawesome/free-solid-svg-icons';

export const patentePack = {
    title: "Cosa offre il nostro pacchetto?",
    paragraph: "",
    list: [
        {
            icon: faBook,
            title: "Lezioni di gruppo o individuali",
            paragraph: "Lezioni di gruppo o individuali, a ciclo continuo e con cadenza settimanale. Gli orari vengono decisi a seconda della disponibilità degli allievi",
        },
        {
            icon: faCompassDrafting,
            title: "Materiale didattico",
            paragraph: "Libro di teoria, quiz app, carta 5D, compasso professionale, squadrette nautiche e cancelleria, il tutto contenuto in una pratica e comoda borsetta",
        },
        {
            icon: faSailboat,
            title: "Cinque lezioni pratiche",
            paragraph: "Cinque lezioni pratiche (obbligatorie per legge) in cui imparerai a condurre l’imbarcazione, a recuperare un uomo caduto in mare e ad ormeggiare e disormeggiare.",
        },
        {
            icon: faCodeCommit,
            title: "Lezioni sui nodi",
            paragraph: "Lezione pratica sui principali nodi marinari che vengono chiesti in sede d’esame con particolare attenzione ai nodi bitta, bozza, gassa e parlato.",
        }
        
    ],


}
