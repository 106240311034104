import React from "react";

const NauticaFeatureSection = () => {
  return (

    <div className="bg-blue-accent-400 w-full md:px-12 lg:px-8 py-12 lg:py-20">
      <div
        className="sm:text-center text-start flex flex-col items-center text-white
      justify-center lg:max-w-screen-xl px-4 md:px-12 lg:px-8  mx-auto"
      >
        <div className="flex items-center justify-center sm:w-24 sm:h-24 w-12 h-12  mb-4 rounded-full ring-2 ring-white">
          <svg
            fill="currentColor"
            className="w-1/2 h-1/2 fill:white"
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 576 512"
          >
            <path d="M256 16c0-7 4.5-13.2 11.2-15.3s13.9 .4 17.9 6.1l224 320c3.4 4.9 3.8 11.3 1.1 16.6s-8.2 8.6-14.2 8.6H272c-8.8 0-16-7.2-16-16V16zM212.1 96.5c7 1.9 11.9 8.2 11.9 15.5V336c0 8.8-7.2 16-16 16H80c-5.7 0-11-3-13.8-8s-2.9-11-.1-16l128-224c3.6-6.3 11-9.4 18-7.5zM5.7 404.3C2.8 394.1 10.5 384 21.1 384H554.9c10.6 0 18.3 10.1 15.4 20.3l-4 14.3C550.7 473.9 500.4 512 443 512H133C75.6 512 25.3 473.9 9.7 418.7l-4-14.3z" />
          </svg>
        </div>

        <h2
          className="mb-6 font-sans
          sm:text-6xl text-4xl font-extrabold tracking-tight text-white sm:leading-none"
        >
          Perché scegliere il nostro corso di <br /> Patente Nautica:
        </h2>
        <div className="sm:w-7/8 w-full space-y-2 flex flex-col text-2xl">
          <p className=" text-white">
            Dall'entrata in vigore della nuova normativa, conseguire la patente
            nautica non è stato mai così facile:
          </p>
          <p className=" text-white">
            Snellita infatti la parte di carteggio ed eliminate domande
            dispersive e non più attinenti, <br />superare l'esame è ora alla portata
            di tutti!
          </p>
          <p className=" text-white">
            Affidati al nostro staff: ti forniremo una preparazione aggiornata e
            focalizzata sul superamento dei nuovi quiz
          </p>
        </div>
      </div>
    </div>
  );
};

export default NauticaFeatureSection;
