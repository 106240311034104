import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FeaturePackage = (props) => {
  const { pack } = props;

  return (
    <section id={props.id}>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col sm:items-start items-center mb-12 lg:flex-row">
          <div className="w-full">
            <h2 className="font-sans font-extrabold tracking-tight text-blue-accent-700 sm:text-6xl text-5xl  leading-24 text-center sm:text-start ">
              {pack.title}
            </h2>
          </div>
        </div>
        <div className="grid gap-8 row-gap-10 sm:grid-cols-2 lg:grid-cols-4">
          {pack.list.map((p) => (
            <div className="flex flex-col sm:items-start items-center">
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-blue-accent-700">
                <FontAwesomeIcon
                  icon={p.icon}
                  className="fa solid fa-car h-6 w-6 text-white"
                />
              </div>
              <h3 className="text-3xl mb-4 font-roboto font-semibold tracking-tight text-blue-accent-700 sm:text-start text-center">
                {p.title}
              </h3>
              <p className="mb-3 text-xl font-roboto font-semibold tracking-tight text-gray-800 leading-8 sm:text-start text-center">
                {p.paragraph}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturePackage;
